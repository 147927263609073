<template>
  <div class="mainBox page-container">
    <Breadcrumb></Breadcrumb>

    <!--  操作区-->
    <el-card>
      <el-form
          :inline="true"
          :model="selectInfo"
          class="demo-form-inline"
          size="small"
          ref="selectOptionsFormRef"
      >
        <el-form-item label="患者信息" prop="keyword">
          <el-input
              v-model="selectInfo.keyword"
              placeholder="请输入患者姓名/手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="医生工作室" prop="groupId">
          <el-select
              v-model="selectInfo.groupId"
              placeholder="选择工作室"
              filterable
              remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
                v-for="(item, index) in doctorOfficeList"
                :key="index"
                :label="item.groupName"
                :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医院" prop="hospitalId">
          <el-select
              v-model="selectInfo.hospitalId"
              placeholder="选择医院"
              filterable
              remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
                v-for="(item, index) in hospitalList"
                :key="index"
                :label="item.hospitalName"
                :value="item.hospitalId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联医助" prop="assistantId">
          <el-select
              v-model="selectInfo.assistantId"
              placeholder="选择关联医助"
              filterable
              remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
                v-for="(item, index) in assistantList"
                :key="index"
                :label="item.assistantName"
                :value="item.assistantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联健康顾问" prop="sellerName">
          <el-input
              v-model="selectInfo.sellerName"
              placeholder="请输入关联健康顾问"
          ></el-input>
        </el-form-item>
        <el-form-item label="入组时间" prop="joinTimeRange">
          <el-date-picker
              v-model="selectInfo.joinTimeRange"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="宣教时间" prop="teachTimeDateRange" v-show="activeType==='1'">
          <el-date-picker
              v-model="selectInfo.teachTimeDateRange"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="resetSelectOptions"
          >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="formregion">
      <el-tabs @tab-click="handleClick" v-model="activeType" type="card">
        <el-tab-pane label="待处理" name="0"> </el-tab-pane>
        <el-tab-pane label="已处理" name="1"> </el-tab-pane>
      </el-tabs>
      <el-table :data="teachList" border size="mini" style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="userName"
            label="患者姓名"
            width="120"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="phoneNo"
            label="患者手机号"
            width="120"

            align="center"
        ></el-table-column>
        <el-table-column
            prop="hospitalName"
            label="医院名称"
            align="center"
        ></el-table-column>
        <el-table-column prop="groupName" label="医生工作室" align="center">
          <template slot-scope="socpe">
            <span v-if="socpe.row.groupName == null">-</span>
            <span v-else>{{ socpe.row.groupName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="assistantName"
            label="关联医助"
            width="120"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="sellerName"
            label="关联健康顾问"
            width="120"
            align="center"
        ></el-table-column>
        <el-table-column prop="payTime" label="入组时间" align="center" width="120">
          <template slot-scope="socpe">


            <span v-if="!socpe.row.payTime">--</span>
            <span v-else>{{socpe.row.payTime | dateFormat("YYYY-MM-DD") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="入组时长" align="center" width="80">
          <template slot-scope="socpe">
            <span v-if="!socpe.row.duration">-</span>
            <span v-else>{{ socpe.row.duration }}天</span>
          </template>
        </el-table-column>
        <el-table-column prop="times" label="宣教次数" align="center" width="80">
          <template slot-scope="socpe">
            <span v-if="!socpe.row.times">--</span>
            <span v-else>{{ socpe.row.times }}次</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="overview"
            label="概述"
            align="center"
        >
          <template slot-scope="scope">
            <el-popover
                placement="top-start"
                title="概述"
                width="300"
                trigger="hover"
            >
              <div v-html="scope.row.overview"></div>
              <span slot="reference">{{scope.row.overview | filterHtml}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="conclusion"
            label="宣教结果"
            align="center"
            v-if="activeType==='1'"
        >
          <template slot-scope="scope">
            <el-popover
                placement="top-start"
                title="宣教结果"
                width="300"
                trigger="hover"
                :content="scope.row.conclusion"
            >
              <span slot="reference">{{scope.row.conclusion}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="teachTime" label="宣教时间" align="center" width="120" v-if="activeType==='1'"
        >
          <template slot-scope="socpe">
            <span v-if="!socpe.row.teachTime">--</span>
            <span v-else>{{socpe.row.teachTime | dateFormat("YYYY-MM-DD") }}</span>
          </template>
        </el-table-column>

        <el-table-column
            prop="vipstate"
            label="操作列"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <el-button @click="openEducatioDialog(scope.row)" type="text" size="small" v-show="activeType==='0'">宣教</el-button>
            <el-button @click="showRelationsDialog(scope.row)" type="text" size="small" v-show="activeType==='1'">呼叫</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pag_warpper">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagenum"
            :page-sizes="[10, 20, 50]"
            :page-size="pagesize"
            layout="slot, sizes, prev, pager, next, jumper"
            :total="total"
            style="margin-left: 30px"
        >
          <span class="total"
          >共 {{ total }} 条数据，共
            {{ Math.ceil(total / pagesize) }} 页</span
          >
        </el-pagination>
      </div>
    </div>
    <!--    宣教对话框-->
    <el-dialog
        title="患者宣教"
        :visible.sync="educatioDialogVisiable"
        width="30%"
        @closed="dialogClosedEvent"
        :close-on-click-modal="false"
        :append-to-body="true"
    >
      <!--      内容主体区-->
      <div class="relationsPhoneList">
        <div class="title" v-for="(item, index) in relationsList" :key="index">
          <div>{{ item.name }} :{{ item.phone }}</div>
          <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
        </div>
      </div>
      <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
        <el-form-item label="是否应答" prop="isAnswer" label-width="100px">
          <el-radio v-model="teachFrom.isAnswer" :label="1">是</el-radio>
          <el-radio v-model="teachFrom.isAnswer" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="宣教时间" prop="name" label-width="100px" v-show="teachFrom.isAnswer===1">
          <div class="teachTime-box">
            <el-date-picker
                style="margin-bottom: 12px"
                v-model="teachFrom.teachTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
            <el-time-picker
                v-model="teachFrom.handM"
                value-format="HH:mm:ss"
                placeholder="任意时间点">
            </el-time-picker>
          </div>
        </el-form-item>
        <el-form-item label="下次提醒时间" prop="name" label-width="100px" v-show="teachFrom.isAnswer===0">
          <div class="teachTime-box">
            <el-date-picker
                style="margin-bottom: 12px"
                v-model="teachFrom.nextTime"
                type="date"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
            <el-time-picker
                v-model="teachFrom.handM"
                value-format="HH:mm:ss"
                placeholder="任意时间点">
            </el-time-picker>
          </div>
        </el-form-item>
        <el-form-item label="宣教结果" prop="des" label-width="100px" v-show="teachFrom.isAnswer===1">
          <el-input
              v-model="teachFrom.conclusion"
              type="textarea"
              :maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="educatioDialogVisiable = false">取 消</el-button>
        <el-button type="primary" @click="confirmEducation">确 定</el-button>
      </span>
    </el-dialog>
<!--    通讯录-->
    <el-dialog
        :visible.sync="relationsDialog"
        width="360px"
        :show-close="false"
        :modal="false"
        class="callAndUnbundleBox"
    >
      <div class="callAndUnbundleCon">
        <div class="title" v-for="(item, index) in relationsList" :key="index">
          <div>{{ item.name }} :{{ item.phone }}</div>
          <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
        </div>
        <div class="associatedBtn">
          <div class="associatedCancel" @click="relationsDialog = false">
            取消
          </div>
          <!--          <div class="associatedSure" @click="callAndUnbundleSure()">确定</div>-->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { timeMode } from "../../tools/utils";
export default {
  name: "patienteducation",
  data() {
    return {
      timeMode,
      relationsDialog: false,
      //提醒参数
      remindParams: {},
      //提醒记录
      recordList: [],
      //添加工单管理对话框
      educatioDialogVisiable: false,
      //总条数
      total: 0,
      doctorOfficeList: [],
      assistantList: [],
      hospitalList: [],
      teachList: [],
      //查询数据
      selectInfo: {
        keyword: "",
        groupId: "",
        sellerName: "",
        joinTimeRange: [],
        teachTimeDateRange: [],
        assistantId: '',
        hospitalId: '',
      },
      addOrderDialogClose: false,
      pagenum: 1,
      pagesize: 10,
      activeType: '0',
      //待分配列表患者
      toassignePatients: [],
      currentPatientInfo: {},
      userInfo: "",
      teachFrom: {
        teachId: '',
        userId: '',
        isAnswer: 1,
        conclusion: '',
        teachTime: '',
        nextTime: '',
        handM:''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();  //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      relationsList:[]
    }
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.getOfficeList();
    this.getAssistantName();
    this.getHospitalName();
    this.getTeachList(this.selectInfo,1,10)
  },
  //总条数
  computed: {},
  mounted() {},
  beforeRouteLeave(to, from, next) {
    console.log(to.path, "==========================");
    if (to.path === "/taskcenter") {
      sessionStorage.removeItem("selectParams");
    }
    next();
  },
  methods: {
    //重置筛选项
    resetSelectOptions() {
      console.log("重置筛选项");
      this.$refs.selectOptionsFormRef.resetFields();
      this.getTeachList(this.selectInfo,1,10)
    },
    //查询按钮
    async onSubmit() {
      this.getTeachList(this.selectInfo,this.pagenum,this.pagesize);
    },
    handleClick() {
      //重置表单
      this.teachList=[]
      this.$refs.selectOptionsFormRef.resetFields();
      this.getTeachList(this.selectInfo,1,10)
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.getTeachList(this.selectInfo,this.pagenum,this.pagesize)
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pagenum = val;
      this.getTeachList(this.selectInfo,this.pagenum,this.pagesize)
    }, //获取患者数据
    //获取工作室列表
    getOfficeList(){
      this.$http.getGroupNameFromCustomer({customerServerId:this.userInfo.customerServerId}).then(res=>{
        this.doctorOfficeList = res.result.data;
      })
    },
    //获取关联医助
    getAssistantName(){
      this.$http.getAssistantName({customerServerId:this.userInfo.customerServerId}).then(res=>{
        console.log(res,'========获取关联医助=======')
        this.assistantList = res.result.data;
      })
    },
    //获取医院
    getHospitalName(){
      this.$http.getHospitalName({customerServerId:this.userInfo.customerServerId}).then(res=>{
        console.log(res,'========获取医院姓名=======')
        this.hospitalList = res.result.data;
      })
    },
    //获取宣教列表
    getTeachList(selectInfo,page,pageSize){
      let params = {
        customerServerId:this.userInfo.customerServerId,
        keyword:selectInfo.keyword,
        groupId:selectInfo.groupId,
        sellerName:selectInfo.sellerName,
        assistantId:selectInfo.assistantId,
        hospitalId:selectInfo.hospitalId,
        joinTimeStart:selectInfo.joinTimeRange[0] ? selectInfo.joinTimeRange[0] : '',
        joinTimeEnd:selectInfo.joinTimeRange[1] ? selectInfo.joinTimeRange[1] : '',
        teachTimeStart:selectInfo.teachTimeDateRange[0] ? selectInfo.teachTimeDateRange[0] : '',
        teachTimeEnd:selectInfo.teachTimeDateRange[1] ? selectInfo.teachTimeDateRange[1] : '',
        findType:parseInt(this.activeType),
        page,
        pageSize,
      }
      console.log(params)
      this.$http.getTeachList(params).then(res=>{
        console.log(res,'========宣教列表=======')
        this.pagesize = res.result.data.rows;
        this.total = res.result.data.records;
        this.pagenum = res.result.data.page;
        this.teachList = res.result.data.data
      }).catch(err=>{
        this.$message.error(`获取宣教列表失败:${err.msg}`)
      })
    },
    //打开患者宣教对话框
    openEducatioDialog(row){
      console.log(row)
      this.teachFrom.teachId = row.teachId
      this.teachFrom.userId = row.userId


      let params = {
        userId: row.userId,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        console.log(res);
        this.relationsList = res.result.data;
        this.educatioDialogVisiable = true;
      });


      // if(this.$store.state.calling.isLogin){
      //   let params = {
      //     userId: row.userId,
      //   };
      //   this.$http.getRelationsPhone(params).then((res) => {
      //     console.log(res);
      //     this.relationsList = res.result.data;
      //     this.educatioDialogVisiable = true;
      //   });
      // }else {
      //   this.$message.warning({
      //     message:'请先登录呼叫中心!',
      //     duration:1000
      //   })
      // }
    },
    dialogClosedEvent(){
      console.log('6666666666关闭了')
      this.teachFrom.conclusion=''
      this.teachFrom.teachTime=''
      this.teachFrom.nextTime=''
      this.teachFrom.handM=''
    },
    //确认添加患者
    confirmEducation(){
      console.log(this.teachFrom)
      if (this.teachFrom.isAnswer===1){
        if (this.isFinishTeachFrom([this.teachFrom.teachTime,this.teachFrom.handM,this.teachFrom.conclusion])){
          console.log('填写完了')
          this.updateTeachReq()
        }else {
          this.$message.warning(`请填写完整!`)
        }
      }
      if (this.teachFrom.isAnswer===0){
        if (this.isFinishTeachFrom([this.teachFrom.nextTime,this.teachFrom.handM])){
          console.log('填写完了')
          this.updateTeachReq()
        }else {
          this.$message.warning(`请填写完整!`)
        }
      }
    },
    //判断是否填写完整
    updateTeachReq(){
      let params = {
        teachId:this.teachFrom.teachId,
        userId:this.teachFrom.userId,
        isAnswer:this.teachFrom.isAnswer,
        conclusion:this.teachFrom.isAnswer ? this.teachFrom.conclusion : '',
        teachTime:this.teachFrom.isAnswer ?  this.teachFrom.teachTime + ' ' +this.teachFrom.handM : '',
        nextTime:this.teachFrom.isAnswer ?  '' : this.teachFrom.nextTime + ' ' +this.teachFrom.handM,
      }
      console.log(params)
      this.$http.updateTeach(params).then(res=>{
        console.log(res,'========宣教=======')
        this.$message.success('处理成功!')
        this.educatioDialogVisiable = false;
        this.getTeachList(this.selectInfo,this.pagenum,this.pagesize)

      }).catch(err=>{
        this.$message.error(`宣教失败:${err.msg}`)
      })
    },
    isFinishTeachFrom(argArr=[]){
      console.log(argArr)
      console.log(argArr.every(item => item!==''))
      return argArr.every(item => item!=='')
    },

    showRelationsDialog(val) {
      console.log(val);
      console.log(this.$store.state.calling.isLogin)
      let params = {
        userId: val.userId,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        console.log(res);
        this.relationsList = res.result.data;
        this.relationsDialog = true;
      });

      // if(this.$store.state.calling.isLogin){
      //   let params = {
      //     userId: val.userId,
      //   };
      //   this.$http.getRelationsPhone(params).then((res) => {
      //     console.log(res);
      //     this.relationsList = res.result.data;
      //     this.relationsDialog = true;
      //   });
      // }else {
      //   this.$message.warning({
      //     message:'请先登录呼叫中心!',
      //     duration:1000
      //   })
      // }

    },

    telNum(phone) {
      ClinkAgent.previewOutcall({ tel: phone });
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  display: flex;
  flex-direction: column;
  /*height: 100%*/
}
/*.el-form {*/
/*  margin-left: 20px;*/
/*}*/
.el-form-item {
  margin-right: 30px;
}
/*.el-tabs {*/
/*  margin-left: 30px;*/
/*}*/
.el-tab-pane {
  max-height: 560px;
  overflow-y: auto;
}
/deep/.el-table{
  margin-top: 0;
}
.formregion {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px 10px 10px 0;
  flex: 1;
  box-sizing: border-box;
  padding: 16px 20px;
}
.pag_warpper {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}
.el-card {
  flex-shrink: 0;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
/deep/.callAndUnbundleBox {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
    box-sizing: border-box;
    .callAndUnbundleCon {
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        span {
          color: #409eff;
        }
      }
      .associatedBtn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .associatedCancel {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .associatedSure {
          width: 120px;
          height: 40px;
          background: #4facff;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
}
.teachTime-box{
  display: flex;
  flex-direction: column;
}
.relationsPhoneList{
  padding: 0 24px;
  margin-bottom: 12px;
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    span {
      color: #409eff;
    }
  }
}
</style>
