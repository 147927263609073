import { render, staticRenderFns } from "./patienteducation.vue?vue&type=template&id=5766b916&scoped=true"
import script from "./patienteducation.vue?vue&type=script&lang=js"
export * from "./patienteducation.vue?vue&type=script&lang=js"
import style0 from "./patienteducation.vue?vue&type=style&index=0&id=5766b916&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5766b916",
  null
  
)

export default component.exports